.text-accordion__wrapper{
  margin-bottom: 60px;

  @media (max-width: 992px) {
    margin-bottom: 40px;
  }

  .text-accordion{
    padding: 24px 0 24px 13px;
    border-top: 1.5px solid #99CAB8;

    &:last-child{
      border-bottom: 1.5px solid #99CAB8;
    }

    @media (max-width: 992px) {
      padding: 18px 0;
    }

    .text-accordion__toggler {
      cursor:pointer;
      display: flex;
      align-items: center;
      margin-bottom: 0 !important;

      @media (max-width: 992px) {
        font-size: rem(21px);
        line-height: rem(32px);
      }

      &:before{
        content: '';
        display: inline-block;
        width: 28px;
        height: 28px;
        min-width: 28px;
        margin-right: 12px;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAyOCAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0IDQuNjY2NzVWMjMuMzMzNCIgc3Ryb2tlPSIjMDg0MDJCIiBzdHJva2Utd2lkdGg9IjMiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTQuNjY2MDIgMTRIMjMuMzMyNyIgc3Ryb2tlPSIjMDg0MDJCIiBzdHJva2Utd2lkdGg9IjMiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTE0IDQuNjY2NzVWMjMuMzMzNCIgc3Ryb2tlPSIjMDg0MDJCIiBzdHJva2Utd2lkdGg9IjMiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTQuNjY2MDIgMTRIMjMuMzMyNyIgc3Ryb2tlPSIjMDg0MDJCIiBzdHJva2Utd2lkdGg9IjMiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==');
        background-repeat: no-repeat;
      }
    }

    .text-accordion__content {
      padding: 24px 0 0 35px;

      p{
        &:last-child{
          margin-bottom: 0 !important;
        }
      }
    }

    &.active{
      border-top-color: $GK-Dark;
      padding-bottom: 60px;

      .text-accordion__toggler {
        &::before{
          background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAyOCAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQuNjY2MDIgMTRIMjMuMzMyNyIgc3Ryb2tlPSIjMDg0MDJCIiBzdHJva2Utd2lkdGg9IjMiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTQuNjY2MDIgMTRIMjMuMzMyNyIgc3Ryb2tlPSIjMDg0MDJCIiBzdHJva2Utd2lkdGg9IjMiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==');
        }
      }
    }

    p{
      a.link{
        display: table;
        font-size: rem(24px);
        line-height: rem(32px);
        font-family: 'freightsans_promedium';
        padding-bottom: 2px;
        //border-bottom: 1.5px solid $GK-Green;
        text-decoration: none;
        color: $GK-Dark;
        margin-bottom: 24px;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }


  &.font-28{
    .text-accordion{
      h6.text-accordion__toggler {
        font-size: rem(28px);
        line-height: rem(36px);
        @media(max-width: 992px){
          font-size: rem(21px);
          line-height: rem(32px);
        }
      }
      p{
        a.link{
          font-size: rem(28px);
          line-height: rem(36px);
          @media(max-width: 992px){
            font-size: rem(21px);
            line-height: rem(32px);
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}
