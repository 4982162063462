.gk-form{

  .input-group{
    position: relative;
    margin-bottom: 50px;

    label{
      display: block;
      margin-bottom: 8px;
      color: $GK-Dark;
      font-family: 'freightsans_promedium';
      font-size: rem(18px);
      line-height: rem(28px);
      letter-spacing: 0.5px;
    }

    input[type=text] , input[type=email] , input[type=number] , input[type=password] , input[type=phone] , textarea{
      width: 100%;
      padding: 12px 20px;
      border: 1.5px solid #99CAB8;
      color: $GK-Dark;
      font-family: 'freightsans_promedium';
      font-size: rem(21px);
      line-height: rem(32px);
      box-shadow: none !important;
      border-radius: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-border-radius: 0;

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $GK-Gray;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $GK-Gray;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        color: $GK-Gray;
      }

      &:focus{
        border-bottom-color: $GK-Green;
        outline: none;
      }

      &.fail , &.error , &.touched:required:invalid{
        color: $GK-Red;
        border-color: transparentize($GK-Red, 0.5);
        box-shadow: none;

        &:focus{
          border-bottom-color: $GK-Red;
        }
      }
    }

    input[type=text] , input[type=email] , input[type=number] , input[type=phone] , input[type=password]{

      &:required{
        padding: 12px 20px;
      }

      &:optional{
        padding: 12px 90px 12px 20px;

        & + .optional-txt{
          position: absolute;
          top: 54px;
          right: 45px;
          display: block;
          color: $GK-Gray;
          font-family: 'freightsans_promedium';
          font-size: rem(16px);
          line-height: rem(28px);
          pointer-events: none;

          @media (max-width: 992px) {
            right: 30px;
          }
        }
      }
    }

    // remove input[type=number] arrows
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

    textarea{
      min-height: 59px;
    }

    .optional-txt{
      display: none;
    }

    .dropdown{
      .styledSelect{
        background-color: transparent;
        border: 1.5px solid #99CAB8;

        .dropdown__label{
          font-size: rem(18px);
          line-height: rem(28px);
        }
      }

      .options{
        background-color: white;
        border: 1.5px solid #99CAB8;
      }
    }
  }

  // Custom checkbox
  .custom-checkbox {
    display: inline-block !important;
    position: relative;
    padding-left: 34px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      top: 0;
      left: 0;

      &:focus-visible{
        & + .checkmark{
          background-color: $GK-Strek-Green;
        }
      }
    }

    .checkmark {
      position: absolute;
      top: 5px;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: transparent;
      border: 1.5px solid $GK-Green;

      &:after {
        content: "";
        position: absolute;
        display: none;
      }
    }

    &:hover input ~ .checkmark {
      // background-color: #ccc;
    }

    input:checked ~ .checkmark {
      background-color: $GK-Green;

      &:after {
        display: block;
      }
    }

    .checkmark:after {
      left: 6px;
      top: 2px;
      width: 6px;
      height: 11px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &.error{
      color: $GK-Red !important;

      .checkmark{
        border-color: $GK-Red !important;
      }
    }
  }
}

// FORM BUILDER
.xp-formbuilder-form{
  h2{
    display: none;
  }

  .xp-form-input{
    position: relative;
    margin-bottom: 40px;

    label{
      display: block;

      span{
        &:first-of-type{
          display: inline-block;
          margin-bottom: 8px;
          color: $GK-Dark;
          font-family: 'freightsans_promedium';
          font-size: rem(18px);
          line-height: rem(28px);
          letter-spacing: 0.5px;
        }
      }

      .required-star{
        color: $GK-Red;
      }
    }

    input[type=text] , input[type=email] , input[type=number] , input[type=password] , input[type=phone] , textarea{
      width: 100%;
      padding: 12px 20px;
      border: 1.5px solid #99CAB8;
      color: $GK-Dark;
      font-family: 'freightsans_promedium';
      font-size: rem(21px);
      line-height: rem(32px);
      box-shadow: none !important;
      border-radius: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-border-radius: 0;

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $GK-Gray;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $GK-Gray;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        color: $GK-Gray;
      }

      &:focus{
        border-bottom-color: $GK-Green;
        outline: none;
      }

      &.fail , &.error , &.touched:required:invalid{
        color: $GK-Red;
        border-color: transparentize($GK-Red, 0.5);
        box-shadow: none;

        &:focus{
          border-bottom-color: $GK-Red;
        }
      }
    }

    input[type=text] , input[type=email] , input[type=number] , input[type=phone] , input[type=password]{

      &:required{
        padding: 12px 20px;
      }

      &:optional{
        // padding: 12px 90px 12px 20px;

        & + .optional-txt{
          position: absolute;
          top: 54px;
          right: 45px;
          display: block;
          color: $GK-Gray;
          font-family: 'freightsans_promedium';
          font-size: rem(16px);
          line-height: rem(28px);
          pointer-events: none;

          @media (max-width: 992px) {
            right: 30px;
          }
        }
      }
    }

    // remove input[type=number] arrows
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

    textarea{
      min-height: 59px;
    }

    .optional-txt{
      display: none;
    }

    // Input checkbox
    .input-checkbox__wrapper {
      input[type= checkbox] {
        opacity: 0;
        position: absolute;
        width: 24px;
        height: 24px;
        top: 4px;

        &:checked {
          &+ label:after {
              opacity: 1;
          }
        }

        &:focus-within {
          + label:before {
              opacity: 1;
          }
        }

        + label {
          font-size: 21px;
          position: relative;
          padding-left: 35px;
          color: $GK-Dark;

          @media(max-width: 992px) {
              padding-left: 40px;
              font-size: 18px;
          }

          &:before {
              content: '';
              width: 24px;
              height: 24px;
              background-color: #fff;
              border: 1.5px solid $GK-Green;
              border-radius: 6px;
              top: 4px;
              left: 0;
              position: absolute;
              display: block;
              opacity: 1;
              cursor: pointer;
              z-index: 1;

              @media(max-width: 992px) {
                  top: -2px;
                  width: 28px;
                  height: 28px;
                  border-radius: 6px;
              }
          }

          &:after {
              content: '';
              width: 24px;
              height: 24px;
              background-image: url(../images/icons/checked-white.svg);
              background-color: $GK-Green;
              border-radius: 6px;
              top: 4px;
              left: 0;
              position: absolute;
              background-repeat: no-repeat;
              background-position: center;
              opacity: 0;
              cursor: pointer;
              z-index: 2;

              @media(max-width: 992px) {
                  top: -2px;
                  width: 28px;
                  height: 28px;
              }
          }

          &:hover {
              &:before {
                  opacity: 1;
              }
          }
        }
      }

      .required-star{
        margin-left: 3px;
        color: $GK-Red;
      }
    }

    // Input radio
    .input-radio__wrapper{
      position: relative;
      display: inline-flex;
      align-items: center;
      margin-right: 40px;
      margin-bottom: 10px;

      &:last-child{
        margin-right: 0;
      }

      input[type= radio] {
        opacity: 0;
        position: absolute;
        top: 5px;
        width: 24px;
        height: 24px;
        cursor: pointer;

        @media(max-width: 992px) {
          width: 20px;
          height: 20px;
        }

        &:checked {
          &+ label:after {
              opacity: 1;
          }
        }

        &:focus-within {
          + label:before {
              opacity: 1;
          }
        }

        + label {
          font-size: 21px;
          position: relative;
          padding-left: 35px;
          pointer-events: none;
          color: $GK-Dark;

          @media(max-width: 992px) {
            padding-left: 32px;
            font-size: 18px;
          }

          &:before {
            content: '';
            width: 24px;
            height: 24px;
            background-color: #fff;
            border: 1.5px solid $GK-Green;
            border-radius: 100%;
            top: 5px;
            left: 0;
            position: absolute;
            display: block;
            opacity: 1;

            @media(max-width: 992px) {
                width: 20px;
                height: 20px;
            }
          }

          &:after {
            content: '';
            width: 24px;
            height: 24px;
            background-color: $GK-Green;
            border-radius: 100%;
            top: 5px;
            left: 0;
            position: absolute;
            background-repeat: no-repeat;
            background-position: center;
            opacity: 0;

            @media(max-width: 992px) {
                width: 20px;
                height: 20px;
            }
          }

          &:hover {
            &:before {
                opacity: 1;
            }
          }
        }
      }

      .required-star{
        margin-left: 3px;
        color: $GK-Red;
      }
    }

    // DROPDOWN
    .dropdown {
      position:relative;
      display:inline-block;
      width: 100%;
      height: 58px;
      cursor:pointer;

      @media (max-width: 992px) {
        height: 50px;
      }

      .styledSelect {
        position:absolute;
        top:0;
        right:0;
        left:0;
        background-color:#FFFFFF;
        border: 1.5px solid #99CAB8;
        padding: 12px 44px 12px 20px;
        color: $GK-Dark;
        font-family: 'freightsans_promedium';
        font-size: rem(21px);
        line-height: rem(32px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media (max-width: 992px) {
          padding: 11px 44px 13px 20px;
          font-size: rem(18px);
          line-height: rem(26px);
        }

        &::after{
          content:"";
          position:absolute;
          top: 18px;
          right: 12px;
          width:24px;
          height:24px;
          background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+CjxwYXRoIGQ9Ik0zIDVMMyA5LjQyOTc3TDExLjk5NjggMTlMMjEgOS40Mjk3N0wyMSA1TDExLjk5NjggMTQuNTcwMkwzIDVaIiBmaWxsPSIjMDA3QjRFIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDAiPgo8cmVjdCB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==');
          background-repeat: no-repeat;
          transform: rotate(0);
          transition: 0.25s 0.1s;
          @media (max-width: 992px) {
            top: 15px;
          }
        }

        &:active , &.active{
          //background-color:#eee;

          &::after{
            transform: rotate(180deg);
          }
        }
      }

      .options {
        display:none;
        position:absolute;
        top: 58px;
        right:0;
        left:0;
        z-index:999;
        margin:0;
        padding:0;
        list-style:none;
        background-color:$GK-Light-Green;
        border-top: 1.5px solid #99CAB8;

        @media (max-width: 992px) {
          top: 50px;
        }

        li{
          display: flex;
          align-items: center;
          margin:0;
          padding: 12px 20px !important;
          border-bottom: 1.5px solid #CCE5DC;
          color: $GK-Dark;
          font-family: 'freightsans_probook';
          font-size: rem(21px);
          line-height: rem(32px);
          letter-spacing: 0.5px;

          @media (max-width: 992px) {
            font-size: rem(18px);
            line-height: rem(26px);
          }

          &:last-child{
            border-bottom: none;
          }

          &::before{
            content: '';
            position: relative !important;
            top: 0 !important;
            display: table;
            align-self: flex-start;
            width: 20px !important;
            height: 20px !important;
            margin-top: 8px;
            margin-right: 12px;
            border: 1px solid $GK-Green;
            border-radius: 100%;
            transition: 0.05s;
            background-color: transparent !important;

            @media (max-width: 992px) {
              font-size: rem(18px);
              line-height: rem(26px);
              margin-top: 7px;
            }
          }

          &:hover{
            &::before{
              background-color: $GK-Green !important;
            }
          }
        }
      }
    }
  }

  .xp-submit{
    background-color: $GK-Green;
    color: white;
    padding: 12px 38px;
    font-family: 'freightsans_promedium';
    font-size: rem(18px);
    line-height: rem(28px);
    letter-spacing: 0.5px;
    border: none;
    border-radius: 100px;

    &:hover{
      background-color: #339571;
    }

    &:active{
      background-color: #339571 !important;
    }
  }

  &.form-loading{
    #xp-formbuilder-spinner{
      display: inline-block !important;
      fill: $GK-Green;
      animation: xp-fb-rotation 2s infinite linear !important;
      margin: 0 !important;
    }
  }

  // Thank you message
  p{
    width: 100% !important;
    margin-left: 0 !important;
    color: $GK-Dark;
    font-weight: 600;
  }
}


.input-location-mobile{
  margin: 32px 0 40px 0;
  display: none;

  @media(max-width: 992px){
    display: block;
  }

  input{
    width: 100%;
    padding: 12px 20px 12px 60px;
    border: 1.5px solid #99CAB8;
    color: $GK-Dark;
    font-family: 'freightsans_promedium';
    font-size: rem(16px);
    line-height: rem(32px);
    box-shadow: none !important;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-border-radius: 0;

    background-image: url("../images/icons/map-check.svg");
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-position: 20px center;
    background-color: transparent;

    &.active{
      background-image: url("../images/icons/map-check-active.svg");
    }

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $GK-Gray;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $GK-Gray;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: $GK-Gray;
    }

    &:focus{
      border-bottom-color: $GK-Green;
      outline: none;
    }

    &.fail , &.error , &.touched:required:invalid{
      color: $GK-Red;
      border-color: transparentize($GK-Red, 0.5);
      box-shadow: none;

      &:focus{
        border-bottom-color: $GK-Red;
      }
    }
  }
}
